@import '../../../styles/mixins.scss';

#portal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  .animation {
    &-enter {
      .create-user {
        opacity: 0;
        transform: translateY(-20px);

      }
    
    }
    &-enter-active {

      .create-user {
        opacity: 1;
        transition: 200ms ease-in-out;
        transform: translateY(0);
      }

    }
    &-exit {
      .create-user {
        opacity: 1;
      }
    }
    &-exit-active {
      .create-user{
        opacity: 0;
      }
    }
  }

  .modal {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;

    .drop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 1;
      z-index: 20;
      background-color: rgba(4, 4, 4, 0.2);
      overflow: auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .dark-drop {
      background-color: rgba(0, 0, 0, 0.9);
    }

    .modal-cont {
      width: 100%;
      position: absolute;
      z-index: 20;
    }
  }

  .modal-box {
    width: 631px;
    height: 205px;
    position: relative;
    padding: 46px 18px 22px 45px;
    display: grid;
    grid-gap: 20px;
    background: var(--color-8);
    margin: 85px auto 0 auto;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);
    border-radius: 4px;

    @media (max-width: 900px) {
      width: 100% !important;
      margin: 0;
      box-sizing: border-box;
    }

    .error-message {
      color: var(--input-invalid-color);
      text-align: center;
    }

    .circle {
      position: absolute;
      right: 25px;
      top: 29px;
      border-color: rgba(127, 132, 135, 0.7);
      color: var(--color-8);

      &:hover {
        border-color: rgba(127, 132, 135, 1);
      }
    }

    h3 {
      font-size: var(--size-6);
      display: inline;
      font-weight: 600;
      color: var(--color-14);
    }

    h4 {
      font-size: var(--size-5);
      display: inline-block;
      margin: 50px;
      color: var(--color-14);
    }

    h5 {
      font-size: var(--size-6);
      display: inline;
      font-weight: normal;
      color: var(--color-14);
    }

    h6 {
      font-size: var(--size-4);
      font-weight: bold;
      color: var(--color-14);
    }

    h1 {
      font-size: var(--h2-font-size);
      display: inline;
    }

    header {
      h2 {
        font-size: var(--size-6);
        font-weight: 600;
        color: var(--color-18);
        line-height: 1;
        margin: 0;
      }

      h3 {
        padding: 0;
        margin: 8px 0 0;
        display: block;
        font-weight: 300;
        font-size: var(--size-5);
        color: var(--color-14);
      }

      p {
        padding: 0;
        margin: 7px 0;
        font-weight: 300;
        font-size: var(--size-5);
        color: var(--color-18);
      }
    }

    footer {
      margin-top: 5px;
      justify-self: right;
      align-self: end;
      display: block;
    }

    .button {
      width: max-content;
      height: 40px;
      font-weight: bold;
    }

    .done-button {
      position: absolute;
      bottom: 48px;
      right: 25px;
      font-weight: normal;
    }

    .red,
    .blue,
    .black {
      margin-left: 26px;
    }

    .black {
      background: var(--color-18);
      font-size: var(--size-5);
    }

    .blue {
      background: var(--color-30);
      font-size: var(--size-5);
      border: none;
    }

    .gray {
      border-color: #c7c7c7;
      color: #c7c7c7
    }

    .promote-process {
      grid-template-rows: 93px auto !important;
    }

    .action-process {
      grid-gap: 0;
      grid-template-rows: 1fr 5fr 1fr;

      // & > div {
      //   justify-self: right;
      //   align-self: end;
      // }

      .action-process-body {
        margin-top: 0;
        display: block;

        label.customCheckbox-label {
          display: block;
          padding: 2px 0 11px;
          font-size: var(--size-4);

          .customCheckbox {
            padding-right: 6px;
          }
        }
      }

    }

    &.discrete-job,
    &.remove-photo,
    &.email-message {

      height: auto;
      grid-template-rows: auto;

      h2 {
        white-space: pre-line;
      }

      header {
        margin-bottom: 50px;
      }

      .action-process {
        grid-template-rows: auto;
      }
    }
  
    &.candidate-availability {

      height: 517px;
      padding: 48px 50px 53px 45px;
      grid-gap: 0;
      grid-template-rows: 380px auto;
  
      header {
        height: 14px;
      }
  
      h2 {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 550px;
        white-space: nowrap;
      }
  
      .modal-body {

        .new-company {
          margin: 10px 32px;
        }

        .radio-label:before {
          margin-top: 3.5px;
        }
  
        .scroll-bar {
          position: relative;
          zoom: 1;
          overflow: hidden;
          height: 250px;
          overflow-y: scroll;
  
          &::-webkit-scrollbar {
            width: 5px;
          }
  
          &::-webkit-scrollbar-thumb {
            background: var(--color-4);
          }
  
          &::-webkit-scrollbar-track {
            background: var(--color-8);
          }
        }
  
        .applicant-cards-grid {
          overflow: unset;
          display: grid;
          grid-template-columns: 1fr;
          grid-row-gap: 18px;
          padding: 10px 0 0 0;
          grid-template-rows: 46px;
  
          .applicant-card {
            background: var(--color-8);
            display: grid;
            grid-template-columns: 20px 40px auto;
            grid-column-gap: 12px;
            max-height: 46px;
            position: relative;
            margin-left: 22px;
  
            .default-pic-frame {
              width: 40px;
              height: 40px;
              font-size: var(--size-6);
            }
  
            img {
              border-radius: 50%;
            }
  
            h4 {
              margin: 0;
              height: 22px;
              line-height: 22px;
              font-size: 18px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: var(--color-18);
            }
  
            p {
              font-size: 14px;
              color: var(--color-18);
            }
  
            .radio {
              margin: 0;
  
              input {
                position: absolute;
                opacity: 0;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                height: 100%;
                z-index: 2;
                cursor: pointer;
              }

              .radio-label:before {
                margin-top: 13px;
                margin-left: 7px;
              }
            }
          }
        }
      }

      footer {
        align-self: start;
      }
    }

    &.modal-box-contact-details,
    &.modal-box-employment-info,
    &.modal-box-interview-info{
      padding: 18px 15px 14px 18px;
    }

    &.modal-box-filter-options {
      width: 795px;
      // height: 595px;
      padding: 25px;
      position: relative;

      &.modal-box {
        // height: auto;
        height: auto;
        grid-gap: 0;
        padding-bottom: 150px;
      }

      .modal-close {
        right: 25px;
        top: 25px;
      }

      .date {
        // grid-template-columns: 168px 15px 193px auto;
        grid-column-gap: 10px;
        margin-top: 5px;
      }

      .row {
        display: grid;
        grid-template-columns: 361px auto;
        column-gap: 79px;
        margin-top: 24px;

      }

      .multi-select {
        font-size: var(--size-2);
        margin-top: 33px;


        .bp3-tag {
          font-size: 12px;
          padding: 3px 6px;
        }

        input {
          margin-top: 1px;
          margin-bottom: 1px;
        }

        .bp3-popover-content .bp3-menu {
          .multi-item {
            font-size: 10px;
          }
        }

        // .multi-item {
        //   font-size: var(--size-1);
        // }

      }

      .job-owner {
        margin-top: 31px;

        label {
          font-size: var(--size-4);
        }

        select {
          margin-top: 5px;
          font-size: var(--size-314);
        }
      }

      .match-source {
        margin-top: 31px;

        label {
          font-size: var(--size-4);
        }

        select {
          margin-top: 5px;
          font-size: var(--size-314);
        }
      }

      .input-range-wrapper {

        label {
          margin-top: 34px;
          display: inline-block;
        }

        .input-range {
          margin-top: 33px;
          margin-left: 16px;
          margin-right: 16px;
          width: auto;

          .input-range__label-container {
            font-size: var(--size-1);
          }

          .input-range__label--value {
            left: 2px;
            margin-top: -4px;

            .input-range__label-container {
              width: 35px;
              display: inline-block;
              text-align: center;
            }
            // width: 100px;
          }

          .input-range__label--max {
            .input-range__label-container {
              margin-right: 50px;
            }
          }
        }
      }

      .right-part {

        .status, .availability, .updates {
          margin-top: 2px;
          width: 204px;

          .styled-radio-buttons {
            margin-top: 2px;
          }

          &.job-screen-availability {
            margin-top: 3px;
           }
        }

        .status {
          margin-top: 36px;
        }

       .availability {
         margin-top: 36px;
       }

        .stage {
          margin-top: 32px;

          .row1 {
            display: flex;
          }

          h6 {
            flex: 1;
          }

          .clear-all {
            cursor: pointer;
            font-size: var(--size-3);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.42;
            letter-spacing: normal;
            color: var(--color-26);
            background: transparent;
          }

          .stage-wrapper {
            display: block;
            column-count: 2;

            label {
              color: var(--color-18);
              font-size: var(--size-314);
              font-weight: 600;
              margin-left: 2px;

              &.customCheckbox-label {
                font-size: var(--size-314);
                font-weight: normal;
                display: block;

                .customCheckbox {
                  margin-top: 14px;
                  display: inline-block;
                  vertical-align: bottom;
                  margin-right: 5px;

                  input[type="checkbox"] {
                    width: 18px;
                    height: 18px;
                  }

                  input[type="checkbox"]:before {
                    width: 10px;
                    height: 5px;
                    top: 6px;
                  }

                  input[type="checkbox"]:after {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.modal-box-comment {
      width: 795px;
      height: auto;
      padding: 23px 33px 41px 29px;
      position: relative;

      .write-comment-wrapper {
        position: relative;
        height: 100%;

        .modal-close {
          top: -5px;
          right: -5px;
        }

        textarea {
          margin-top: 31px;
          margin-bottom: 125px;
          height: 210px;
          font-size: var(--size-314);
        }

        .done-button {
          position: absolute;
          bottom: 0px;
          right: 0px;
          font-weight: normal;
        }
      }
    }
  }

  .send-invitation-modal {
    width: 683px;
    height: fit-content;
    padding: 45px 24px 35px 23px;
    grid-row-gap: 0;
    grid-template-rows: 0.8fr auto 1fr 1fr;

    .add-more-container {
      label {
        cursor: pointer;
        font-size: var(--size-4);
        font-weight: bold;
        color: var(--color-2);
      }
    }

    .button {
      font-size: var(--size-5);
      margin-left: 12px;
    }

    .modal-body {
      padding-top: 4px;

      .valid,
      .invalid {
        position: relative;
      }

      .invalid {
        border-color: var(--color-7);
      }

      .missing-role-selection {
        display: inline-block;
        width: 100%;
        line-height: 1em;
        font-size: 12px;
        text-align: right;
        color: var(--color-7);
      }

      .placeHolder {
        font-family: "ploni";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #707070;
      }

      .form {
        .invite-line {
          display: grid;
          grid-template-columns: 40% 5% auto 1%;

          .invite-role {
            font-family: "ploni";
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
          }

          .styled-radio-buttons {
            margin-top: 6px;
          }

          .trash-icon {
            position: relative;
            height: 16px;
            left: 10px;
            align-self: center;
            cursor: pointer;
          }
        }
      }
    }

    footer {
      margin-top: 22px;

      .bk-black {
        min-width: 116px;
      }
    }

    .rdw-editor-wrapper {
      .rdw-editor-main {
        height: 182px;
      }
    }
  }
  .invite-users-modal {
    box-sizing: content-box;
    width: 764px;
    height: 188px;
    // height: fit-content;
    padding: 24px;
    grid-row-gap: 0;
    grid-template-rows: 50px 55px 1fr 1fr;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    h2 {
      font-family: Ploni DL 1.1 AAA;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 35px;
      color: #404040;
    }
    .add-more-container {
      label {
        font-family: Ploni DL 1.1 AAA;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */
        
        // text-align: center;        
        // cursor: pointer;
        // font-size: var(--size-4);
        // font-weight: bold;
        // color: var(--color-2);
      }
    }

    .button {
      font-size: var(--size-5);
      margin-left: 12px;
      width: 113px;
      height: 36px;

      background: #00074F;
      border-radius: 54px;
      font-family: Ploni DL 1.1 AAA;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */
      
      text-align: center;      
    }

    .modal-body {
      padding-top: 4px;

      .valid,
      .invalid {
        position: relative;
      }

      .invalid {
        border-color: var(--color-7);
      }

      .missing-role-selection {
        display: inline-block;
        width: 100%;
        line-height: 1em;
        font-size: 12px;
        text-align: right;
        color: var(--color-7);
      }

      .placeHolder {
        font-family: "ploni";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #707070;
      }

      .form {
        .invite-line-2 {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 24px;

          .invite-role {
            font-family: "ploni";
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
          }

          .styled-radio-buttons {
            margin-top: 6px;
          }

          .trash-icon {
            position: relative;
            height: 16px;
            left: 10px;
            align-self: center;
            cursor: pointer;
          }
        }
      }
    }

    footer {
      margin-top: 22px;

      .bk-black {
        min-width: 116px;
      }
    }

    .rdw-editor-wrapper {
      .rdw-editor-main {
        height: 182px;
      }
    }
  }

  .social-link-modal {
      padding: 25px 40px;
      height: fit-content;
      width: fit-content;
      .title {
        text-align: center;
      }
      .content {
        text-align: left;
        .item {
          padding: 5px 0px;
        }
      }
      .circle-close.modal-close {
        top: 5px;
        right: 10px;
      }
  }

  .status-log-modal {
    height: 492px;

    .scrollable-content {
      margin-top: 36px;
      height: 275px;
      overflow-y: scroll;
      border-bottom: 1px solid var(--link-color);
    }

    table {
      border-spacing: 0;
      width: 100%;
      padding: 0 0 0 19px;
      border-collapse: collapse;

      tr {
        margin-bottom: 30px;
        text-align: left;
        border-bottom: solid 1px var(--link-color);

        & td:first-child {
          color: var(--paragraph-color);
          font-weight: 600;
          text-transform: capitalize;
        }
      }

      th {
        font-size: var(--paragraph-font-size);
        font-weight: 500;
        color: var(--input-background-color);
        padding-left: 20px;
        padding-bottom: 27px;

        &:first-child {
          width: 23.8%;
        }
      }

      td {
        color: var(--paragraph-color-secondary);
        padding-left: 20px;
        line-height: 1.7;
        height: 88px;

        small {
          color: var(--nav-color);
          font-size: var(--table-heading-font-size);
        }
      }
    }

    .button {
      float: right;
    }
  }

  .disabled-button {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }

  .promote-status-modal {
    .form1 {
      //display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: 1fr 2fr;
      grid-gap: 1px 15px;
      margin-top: 5px;
      font-size: var(--table-heading-font-size);
      color: var(--text-color);

      .prom-gap {
        margin-bottom: 28px;
      }

      label {
        display: block;
        padding-bottom: 7px;

        &:nth-child(2) {
          grid-row: 1/3;
          grid-column: 2/3;
        }
      }
    }
  }

  .pause-process-modal {
    //width: 962px;

    .modal-body {
      margin-top: 8px;
    }

    label {
      display: block;
      padding: 7px 0;

      .customCheckbox {
        padding-right: 17px;
      }

      input {
        vertical-align: top;
      }
    }
  }

  .close-process-modal,
  .terminate-process-modal {
    height: 100%;

    .terminate-process-close {
      grid-template-rows: 75px 325px 80px;

      .done-button {
        position: static;
      }
    }

    .terminate-process-open {
      grid-template-rows: 75px 370px 140px 80px;
    }

    .modal-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      margin-top: 8px;
    }

    label {
      display: block;
      padding: 7px 0;
      cursor: pointer;

      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;

      .customCheckbox {
        padding-right: 17px;
      }

      input {
        vertical-align: initial;
        margin: 0 11px 0 0;
      }
    }

    &.radio-label:before {
      width: 20px;
      height: 20px;
      border-radius: 12px;
    }

    .radio-label:before {
      position: relative;
      top: 1px;
    }

    .radio input[type="radio"]:checked + .radio-label:before {
      background-color: #3197ee;
      box-shadow: inset 0 0 0 2px #ffffff;
    }

    .radio input[type="radio"] + .radio-label:before {
      border: solid 2px #b4b4b4;
    }

    .comment {

      margin-top: 70px;
      z-index: 2;

      div {
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
      }
    }

    .radio {
      margin: 0;
    }

    .reason-body {
      display: grid;
      grid-template-columns: 50% 50%;
      
      .new-company {
        margin-bottom: 10px;
        margin-left: 37px;

        label {
          font-weight: bold;
          padding: 0;
        }
      }
    }

    .recruiter-side-header,
    .candidate-side-header {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    .footer{

    }
  }

  .close-process-modal {

    height: 550px;

    .new-company {

      margin-left: 36px;
      margin-bottom: 10px;

      label {
        font-weight: bold;
        padding: 0;
      }
    }

    .terminate-process-open {
      grid-template-rows: 75px 160px 170px 80px;

      .scroll-bar {
        overflow-y: scroll;
        height: 80px;
      }
    }

    .terminate-process-close {
      grid-template-rows: 75px 160px 220px;
    }

    .applicant-cards-grid {
      overflow: unset;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 18px;
      padding: 10px 0 0 0;
      grid-template-rows: 46px;

      .applicant-card {
        background: var(--color-8);
        display: grid;
        grid-template-columns: 20px 40px auto;
        grid-column-gap: 12px;
        max-height: 46px;
        position: relative;
        margin-left: 35px;

        .default-pic-frame {
          width: 40px;
          height: 40px;
          font-size: var(--size-6);
        }

        img {
          border-radius: 50%;
        }

        h4 {
          margin: 0;
          height: 22px;
          line-height: 22px;
          font-size: 18px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: var(--color-18);
        }

        p {
          font-size: 14px;
          color: var(--color-18);
        }

        .radio {
          margin: 0;

          input {
            position: absolute;
            opacity: 0;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            z-index: 2;
            cursor: pointer;
          }
        }
      }
    }
  }

  .close-job-modal {
    height: 517px;
    padding: 48px 50px 53px 45px;
    grid-gap: 0;
    grid-template-rows: 50px 302px 64px;

    header {
      height: 14px;
    }

    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 550px;
      white-space: nowrap;
    }

    .modal-body {
      border: 1px solid #d8d8d8;
      border-style: solid none solid none;

      .scroll-bar {
        position: relative;
        zoom: 1;
        overflow: hidden;
        height: 300px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--color-4);
        }

        &::-webkit-scrollbar-track {
          background: var(--color-8);
        }
      }

      .applicant-cards-grid {
        overflow: unset;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 18px;
        padding: 15px 0 0 0;
        grid-template-rows: 46px;

        .applicant-card {
          background: var(--color-8);
          display: grid;
          grid-template-columns: 20px 40px auto;
          grid-column-gap: 12px;
          max-height: 46px;
          position: relative;

          .default-pic-frame {
            width: 40px;
            height: 40px;
            font-size: var(--size-6);
          }

          img {
            border-radius: 50%;
          }

          h4 {
            margin: 0;
            height: 22px;
            line-height: 22px;
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: var(--color-18);
            max-width: 180px;
          }

          p {
            font-size: 14px;
            color: var(--color-18);
          }

          .radio {
            align-self: center;
            margin: 0;

            input {
              position: absolute;
              opacity: 0;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              height: 100%;
              z-index: 2;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .set-alert-modal {
    //width: 962px;
    .item {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 82px;
      grid-gap: 0 20px;
    }

    textarea {
      margin-top: 11px;
      width: 96%;
    }

    .styled-radio-buttons {
      margin-top: 10px;
    }

    .different-margin {
      margin-bottom: 45px;
    }

    footer {
      margin-top: 80px;
    }
  }

  .expose-modal {
    width: 644px;
    height: 245px;

    h3 {
      color: var(--color-18);
      font-size: var(--size-6);
    }

    h4 {
      color: var(--color-18);
      font-size: var(--size-4);
      font-weight: 500;
      padding: 9px 0 9px 0;
      text-align: left;
      line-height: 1.2;
      margin: 0;
      width: 77%;
    }

    footer {
      margin-top: 36px;
      align-self: center;

      .button {
        text-align: center;
      }

      .okbutton {
        position: relative;
        left: 33%;
      }
    }

    .action-process {
      grid-template-rows: 1fr 1fr;

      &.exposed {
        grid-template-rows: 1fr 1fr;

        footer {
          justify-self: unset;
        }
      }
    }
  }

  .change-password-modal {
    width: 602px;

    .form {
      .form-item {
        width: 55%;
        margin: 11px auto 28px;

        &:last-child {
          margin-bottom: 2px;
        }
      }
    }
  }

  .share-modal {
    //width: 662px;

    .modal-body {
      text-align: center;
    }

    .button {
      width: 186px;
    }

    .profile-share {
      font-size: 19px;
      text-align: center;
      margin: 18px 0;
    }
  }

  .title-modal {
    width: 967px;
    height: 15px;

    .titleManagementContainer {
      padding: 20px;
      top: 0px !important;
      min-height: unset;
      .content {
        padding: 15px
      }
      .multiselect-wrapper .bp3-input {
        max-height: 180px;
      }
    }
    .button {
      width: 186px;
    }
  }

  .add-note-modal {
    height: 492px;

    .form-item {
      margin-top: 16px;

      input {
        height: 50px;
      }

      .rdw-editor-toolbar {
        display: none;
      }

      .rdw-editor-main {
        margin-top: 8px;
        border-radius: 4px;
        height: 151px;

        border: 1px solid var(--color-13);
      }
    }
  }


  .add-note-modal2 {

    height: 650px;
    width: 800px;
    display: block;
    padding: 46px 45px 22px 45px;

    header {

      .circle-close.modal-close {
        top: 40px;
        right: 45px;
      }
    }

    .modal-body {
      margin-top: 40px;

      .note-item {
        .ql-container.ql-snow {
          height: 300px;
        }
      }
    }

  }

  .edit-employer-role-modal {
    width: 684px;
    height: 185px;
    grid-template-columns: 1fr 2fr;
    grid-gap: 6px 0;
    padding: 35px 13px 0 13px;

    footer {
      grid-row-start: 2;
      grid-column-end: 3;
      align-self: auto;

      .button {
        font-size: var(--size-5);
        margin-left: 14px;
      }
    }

    header {
      align-self: center;
    }
  }

  .confirmation-modal, .close-matches-modal, .invite-talent-modal {

    grid-template-rows: 1.7fr 1fr;
    width: 684px;
    height: 205px;
    grid-gap: 28px 10px;

    footer {
      align-self: end;
      margin-bottom: 22px;

      .button {
        font-size: var(--size-5);
        margin-left: 14px;
      }
    }

    header {
      h2 {
        margin-top: 9px;
      }
    }
  }

  .confirmation-force-merge-box {
    height: fit-content;
    grid-template-rows: none;

    .final-result {
      span {
        font-weight: bolder;
      }
    }
  }

  .invite-talent-modal {
    // height: 230px;

    // header {
    //   p {
    //     margin-top: 30px;
    //   }
    // }
    .title {
      display: flex;

      .select-email {
        margin-left: 10px;
        margin-top: 2px;
      }
    }
  }

  .confirmation-modal2 {
    font-family: Ploni DL 1.1 AAA;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    height: initial;
    width: 287px;
    padding: 22px 0 0 0;
    color: #404040;
    grid-template-rows: 1.7fr 1fr;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    text-align: center;
    footer {
      position: relative;
      bottom: 16px;
      justify-self: center;

      .button {
        font-size: var(--size-5);
        margin-left: 14px;
      }
    }

    header {

      margin: 0 30px;

      h2 {
        margin-top: 9px;
      }
    }
  }
  .edit-user {
    width: 378px;
    height: 484px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 24px;
  }
  .create-user {
    width: 378px;
    height: 618px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 24px;
  }

  .create-template {
    width: 586px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 24px;
  }


  .delete-rec-modal {
    grid-template-rows: 1.7fr 1fr;
    width: 684px;
    height: 305px;
    grid-gap: 28px 10px;

    footer {
      align-self: end;
      margin-bottom: 12px;

      .button {
        font-size: var(--size-5);
        margin-left: 14px;
      }
    }

    header {
      h2 {
        margin-top: 9px;
      }
    }
  }


  .share-modal {
    height: 265px;
  }

  .contact-me-modal {
    width: 439px;
    height: 694px;
    padding: 60px 26px 1px 24px;
    text-align: center;
    display: block;
    margin-top: 0;

    header {
      p {
        font-size: var(--size-314);
        text-align: center;
        margin-bottom: 14px;
      }

      .profile-image {
        svg {
          width: 108px;
          height: 108px;
        }
      }

      h2 {
        font-size: var(--size-5);
      }
    }

    .modal-body {
      margin: 18px 0 33px;

      textarea {
        height: 296px;
      }
    }

    .button {
      font-size: var(--size-5);
      width: 147px;
      height: 50px;
    }
  }

  .set-up-your-team-modal {
    height: fit-content;
    width: 683px;
    display: block;
    padding: 43px 26px 16px 22px;
    min-height: 260px;

    header {
      margin-bottom: 25px;
      margin-left: 3px;

      h2 {
        margin-bottom: 10px;
      }

      p {
        font-size: var(--size-4);
        margin: 0 0 2px 0;
      }
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 7px;
    }

    .button {
      width: 100%;
      min-width: unset;
      font-size: var(--size-314);
      margin: 0;
      height: 30px;
      padding: 0;

      &.gray {
        background: var(--color-12);
        color: var(--color-18);
      }
    }

    .invited {
      background: #fff;
      border: 1px solid #000;
      color: #000;
      font-size: 22px;
      font-weight: 100;
    }

    .grid-header {
      display: grid;
      grid-template-columns: 1.1fr 0.9fr 1.3fr 1.1fr;
      background: var(--color-12);
      height: 25px;
      margin-bottom: 0;
      align-items: center;
      padding: 0 15px;
      column-gap: 21px;

      li {
        font-size: var(--size-314);
      }
    }

    .grid-body {
      height: 240px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--color-4);
      }

      &::-webkit-scrollbar-track {
        background: var(--color-8);
      }
    }

    .team-mates {
      display: grid;
      grid-template-columns: 136px 110px 174px auto;
      font-size: var(--size-314);
      padding: 15px 9px 13px 9px;
      grid-gap: 21px;
      border: 1px solid var(--color-9);
      border-top: none;
    }

    .name {
      font-weight: bold;
    }

    .email {
      margin-left: 5px;
      word-break: break-all;
    }

    .bordered {
      //border: 1px solid var(--color-20);
      height: 30px;
      align-content: center;
      display: grid;
      padding-left: 7px;
    }

    select {
      height: 30px;
    }
  }

  .messaging-modal {
    width: 821px;
    height: fit-content;
    border: 1px solid #979797;
    padding: 13px 19px 22px;
    margin-top: 108px;

    footer {
      display: grid;
      margin-top: 20px;
      width: 100%;
    }
  }

  .inbox-attachment-modal {
    width: 1000px;
    height: 100%;
    position: relative;
    padding: 0;
    display: grid;
    grid-gap: 20px;
    margin: 50px auto 0 auto;
    background: transparent;

    img {
      width: auto;
      max-width: 1000px;
      text-align: center;
      margin: 0 auto;
    }

    .modal-close {
      position: fixed;
      top: 10px;
      right: 22px;
      color: #fff;
    }
  }

  .employment-info,
  .contact-details {
    height: 100%;
    min-height: 398px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px #979797;
    padding: 16px 15px 16px 25px;
    position: relative;

    label {
      padding: 0 0;
      cursor: default;
    }

    .phone-numbers-header,
    .email-header,
    .current-header,
    .past-header {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
  }

  .employment-info {
    .current-body {
      margin-top: 32px;
    }

    .past-body {
      margin-top: 24px;
    }

    .current-role,
    .past-role,
    .current-company,
    .past-company {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;

      &.current-company,
      &.past-company {
        color: #707070;
      }
    }

    .current-role,
    .past-role {
      margin-top: 3px;
    }

    .past {
      margin-bottom: 20px;
    }
  }

  .contact-details {
    .contact-details-body {
      display: grid;
      grid-template-columns: 50% 50%;
      margin-top: 37px;
    }

    .phone-numbers-content,
    .email-content {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }
  }

  .send-to-company-modal,
  .add-to-matches-modal, .suggest-jobs-to-candidates-modal {
    width: 792px;
    display: block;
    height: fit-content;
    padding: 26px 30px 26px 21px;
    min-height: 540px;

    footer {
      position: absolute;
      bottom: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: left;
      width: 100%;
      left: 0;
      padding: 0 20px;

      &.candidates-footer {
        grid-template-columns: auto 5fr 1fr;
        grid-gap: 12px;
        bottom: auto;
        position: relative;
        margin-top: 30px;
        padding: 0;

        button {
          font-size: var(--size-5);
          padding: 6px 13px;
        }

        @include mobile {
          display: flex;
          gap: 10px;

          button {
            margin: 0;
            font-size: 16px;
          }

          button.gray {
            margin-right: auto;
          }
        }
      }
    }

    .modal-close {
      right: 29px;
      top: 24px;
    }
  }

  .suggest-jobs-to-candidates-modal {
    min-height: 320px;
  }
  .add-to-leeds-modal {
    footer {
      &.candidates-footer {
        grid-template-columns: auto 1fr;

        .send-to-job {
          display: none;
        }
      }
    }
  }

  .send-to-company-modal {
    footer {
      &.candidates-footer {
        grid-template-columns: auto 1fr;

        .add-to-leads {
          display: none;
        }
      }
    }
  }

  .messaging2-modal{
    width: 994px;
    margin-top: 2%;
    height: auto;
    padding: 30px 38px;
    min-height: 500px;

    @include mobile {
      padding: 16px;
      margin-top: 0;
    }

    footer{
      display: grid;
    }
  }

  .find-profile-modal{
    height: auto;
    padding: 30px;
    min-width: 631px;
    width: fit-content;

    .button{
      display: grid;
      justify-self: end;
    }
  }
  
  .process-overview-modal{
    width: 1000px;
    height: auto;
    min-height: 799px;
    padding: 20px 47px 22px 48px;
    margin-top: 3%;

    @include mobile {
      padding: 16px;
      margin-top: 0;

      .process-overview-header > header, .process-overview-body {
        display: flex !important;
        flex-direction: column;
        gap: 16px !important;
      }
    }

    &.unmatch-overview-modal {
      min-height: auto;
    }
    
    h1{
      font-size: 24px;
      font-weight: 300;
      padding: 20px 0 0 0;
      display: inline-block;
    }

    .circle-close.modal-close{
      right: 47px;
      top: 39px;
    }
    
    footer{
      display: grid;
      justify-items: right;
      margin-top: 55px;
    }
  }

  .expose-recruiter-modal {
    height: auto;

    .expose-component {
      display: block;
    }

    .form-item {
      margin: 13px 0;
    }

    h4 {
      width: auto;
    }

    footer {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-items: end;
    }
  }
  
  .modal-box-interview-info{
    min-height: 300px;
    height: 100%;
    
    .interview-info{
      position: relative;
    }
    
    .content{
      margin: 15px 0 30px;
    }
    
    .date-time{
      position: absolute;
      bottom: -3px;
      font-size: 14px;
      color: #888;
    }
  }
  
  .show-exists-processes{
    height: fit-content;
  }

  .warning-modal {
    width: 408px;
    height: 252px;
    header {
      margin: 0px 110px;
      h2 {
        color: var(--color-30);
        font-size: 24px;
        margin: 15px;
      }
      p {
        margin: 15px 0px;
        color: #6c6c6c;
      }
    }
    footer {
      border-top: 1px solid #c7c7c7;
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      .button {
        width: 100px;
      }
    }
  }

}

.dragging-handle{
  
}

@include mobile {
  #portal .modal-box.modal-box-filter-options .row {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    overflow: hidden;
  }
  
  #portal .modal-box.modal-box-filter-options .date {
    display: flex;
    justify-content: space-between;
    gap: 0;
  }

  .from-date-to-date-wrapper .open-button {
    right: 5px !important;
  }

  #portal .share-modal {
    padding: 24px;
  }

  #portal .find-profile-modal {
    width: 100%;
    min-width: 0;
  }
}

